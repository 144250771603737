import request from './request'

// 摊位列表
export const GETboothList = params => {
  return request({
    url: 'admin/v1/booth_list',
    method: 'GET',
    params
  })
}
// 摊位列表
export const GETboothPaget = (id = '') => {
  return request({
    url: 'admin/v1/booth_page?id=' + id,
    method: 'GET'
  })
}
// 摊位列表
export const POSTboothSubmit = data => {
  return request({
    url: 'admin/v1/booth_submit',
    method: 'POST',
    data
  })
}
