<template>
  <div>
    <div class="title">
      <div>
        <span style="margin-right:20px">市场名称</span>
        <el-select
          v-model="search.mk_id"
          clearable
          placeholder="请选择市场名称"
        >
          <el-option
            v-for="item in data.market"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"
            @click.native="subareaClick"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span style="margin-right:20px">所属分类</span>
        <el-select
          v-model="search.seg_id"
          clearable
          placeholder="请选择所属分类"
        >
          <el-option
            v-for="item in subarea"
            :key="item.id"
            :label="item.segmentation_title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span style="margin-right:20px">摊位类型</span>
        <el-select
          v-model="search.booth_type"
          clearable
          placeholder="请选择摊位类型"
        >
          <el-option
            v-for="item in data.stallType"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span style="margin-right:20px">经营状态</span>
        <el-select
          v-model="search.management_forms"
          placeholder="请选择经营状态"
          clearable
        >
          <el-option
            v-for="item in data.stallStatus"
            :key="item.id"
            :label="item.mf_title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="title2">
      <el-input
        v-model="search.name"
        style="width:217px"
        placeholder="输入关键字搜索"
      ></el-input>

      <el-button type="primary" @click="GET_boothList" style="margin-left:20px"
        >查 询</el-button
      >
      <el-button
        style="margin-left:20px"
        @click="centerDialogVisible = true"
        type="primary"
        >新 增</el-button
      >
    </div>

    <el-table
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center' }"
      :data="tableData.data"
      border
      style="width: 100%;margin-top:20px"
    >
      <el-table-column prop="comp_name" label="市场名称"> </el-table-column>
      <el-table-column prop="segmentation_title" label="所属分类">
      </el-table-column>
      <el-table-column prop="booth_numbers" label="摊位编号"> </el-table-column>
      <el-table-column prop="booth_name" label="摊位类型"></el-table-column>
      <el-table-column prop="booth_footprint" label="摊位大小">
      </el-table-column>
      <el-table-column prop="mf_title" label="经营状态"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click.native="statusAlter(scope.row)"
            >编 辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      width="40%"
      center
      @close="closeadd"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="elform">
          <div>
            <el-form-item label="所属市场" prop="mk_id">
              <el-select v-model="ruleForm.mk_id" placeholder="请选择市场名称">
                <el-option
                  v-for="item in data.market"
                  :key="item.id"
                  :label="item.comp_name"
                  :value="item.id"
                  @click.native="GET_searchSegmentation"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属分类" prop="seg_id">
              <el-select v-model="ruleForm.seg_id" placeholder="请选择所属分类">
                <el-option
                  v-for="item in data.subarea"
                  :key="item.id"
                  :label="item.segmentation_title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经营状态" prop="management_forms">
              <el-select
                v-model="ruleForm.management_forms"
                placeholder="请选择经营状态"
              >
                <el-option
                  v-for="item in data.stallStatus"
                  :key="item.id"
                  :label="item.mf_title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="摊位经度" prop="booth_longitude">
              <el-input
                style="width:217px"
                placeholder="请输入摊位经度"
                v-model="ruleForm.booth_longitude"
              ></el-input>
            </el-form-item>
            <el-form-item label="摊位型号" prop="booth_version">
              <el-select
                v-model="ruleForm.booth_version"
                placeholder="请选择经营状态"
              >
                <el-option
                  v-for="item in data.boothVersionData"
                  :key="item.id"
                  :label="item.version_title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="摊位编号" prop="booth_numbers">
              <el-input
                style="width:217px"
                placeholder="请输入摊位编号"
                v-model="ruleForm.booth_numbers"
              ></el-input>
            </el-form-item>
            <el-form-item label="摊位类型" prop="booth_type">
              <el-select
                v-model="ruleForm.booth_type"
                placeholder="请选择摊位类型"
              >
                <el-option
                  v-for="item in data.stallType"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="摊位面积" prop="booth_footprint">
              <el-input
                placeholder="请输入摊位面积"
                style="width:217px"
                v-model="ruleForm.booth_footprint"
              ></el-input>
            </el-form-item>
            <el-form-item label="摊位纬度" prop="booth_latitude">
              <el-input
                style="width:217px"
                placeholder="请输入摊位纬度"
                v-model="ruleForm.booth_latitude"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >确 认</el-button
              >
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getmarket,
  GETsearchSegmentation,
  GETsearchBoothType,
  GETsearchStatus
} from '../../../api/layout'
import {
  GETboothList,
  GETboothPaget,
  POSTboothSubmit
} from '../../../api/stall'
export default {
  name: 'stallList',
  data () {
    return {
      title: '添加摊位',
      tableData: '', // 表格数据
      subarea: '',
      data: {
        market: '',
        subarea: '',
        stallType: '',
        stallStatus: '',
        boothVersionData: ''
      },
      search: {
        mk_id: '', // 市场
        seg_id: '', // 分区
        booth_type: '', // 摊位类型
        management_forms: '', // 经营状态
        page: '', // 页数
        limit: '', // 条数 默认10
        name: '' // 搜索
      },
      ruleForm: {
        mk_id: '', // 市场id
        seg_id: '', // 市场所属分类id
        booth_numbers: '', // 摊位编号
        booth_type: '', // 类型：1摊位，2为门面
        booth_footprint: '', // 摊位面积
        booth_version: '', // 摊位型号
        management_forms: '', // 经营状态
        booth_longitude: '', // 摊位经度
        booth_latitude: '' // 摊位纬度
      },
      ID: '',
      rules: {
        mk_id: [{ required: true, message: '请选择所属市场', trigger: 'blur' }],
        seg_id: [
          { required: true, message: '请选择所属分类', trigger: 'blur' }
        ],
        booth_numbers: [
          { required: true, message: '请输入摊位编号', trigger: 'blur' }
        ],
        booth_type: [
          { required: true, message: '请选择摊位类型', trigger: 'blur' }
        ],
        booth_footprint: [
          { required: true, message: '请输入摊位面积', trigger: 'blur' }
        ],
        booth_version: [
          { required: true, message: '请选择摊位型号', trigger: 'blur' }
        ],
        management_forms: [
          { required: true, message: '请选择经营状态', trigger: 'blur' }
        ],
        booth_longitude: [
          { required: true, message: '请输入摊位经度', trigger: 'blur' }
        ],
        booth_latitude: [
          { required: true, message: '请输入摊位纬度', trigger: 'blur' }
        ]
      },
      centerDialogVisible: false
    }
  },
  methods: {
    get_market () {
      getmarket().then(res => {
        this.data.market = res.data
      })
    },
    subareaClick () {
      this.search.seg_id = ''
      GETsearchSegmentation(this.search.mk_id).then(res => {
        this.subarea = res.data
      })
    },
    GET_searchSegmentation () {
      this.ruleForm.seg_id = ''
      GETsearchSegmentation(this.ruleForm.mk_id).then(res => {
        this.data.subarea = res.data
      })
    },
    GET_searchBoothType () {
      GETsearchBoothType().then(res => {
        this.data.stallType = res.data
      })
    },
    GET_searchStatus () {
      GETsearchStatus().then(res => {
        this.data.stallStatus = res.data
      })
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.search.limit = val
      this.GET_boothList()
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.search.page = val
      this.GET_boothList()
    },
    // 表格数据
    GET_boothList () {
      GETboothList(this.search).then(res => {
        this.tableData = res.data
      })
    },
    // 编辑
    async statusAlter (row) {
      this.title = '修改摊位信息'
      this.ID = row.id
      this.ruleForm.mk_id = row.mk_id // 市场id
      await this.GET_searchSegmentation()
      this.ruleForm.seg_id = row.seg_id // 市场所属分类id
      this.ruleForm.booth_numbers = row.booth_numbers // 摊位编号
      this.ruleForm.booth_type = row.booth_type // 类型：1摊位，2为门面
      this.ruleForm.booth_footprint = row.booth_footprint // 摊位面积
      this.ruleForm.booth_version = row.booth_version // 摊位型号
      this.ruleForm.management_forms = row.management_forms // 经营状态
      this.ruleForm.booth_longitude = row.booth_longitude // 摊位经度
      this.ruleForm.booth_latitude = row.booth_latitude // 摊位纬度

      this.centerDialogVisible = true
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = {
            ...this.ruleForm
          }
          if (this.title === '添加摊位') {
            POSTboothSubmit(data).then(res => {
              if (res.code === 200) {
                this.centerDialogVisible = false
                this.GET_boothList()
                this.$message.success(`${res.data}`)
                this.closeadd()
              } else {
                this.$message.error(`${res.msg}`)
              }
            })
          } else {
            data.id = this.ID
            POSTboothSubmit(data).then(res => {
              if (res.code === 200) {
                this.centerDialogVisible = false
                this.GET_boothList()
                this.$message.success(`${res.data}`)
                this.closeadd()
              } else {
                this.$message.error(`${res.msg}`)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    querySearchAsync (queryString, cb) {
      console.log(queryString, cb)
      // 调用 callback 返回建议列表的数据
      cb()
    },

    handleSelect (item) {
      console.log(item)
    },
    // 清空
    closeadd () {
      this.title = '添加摊位'
      this.ID = ''
      this.ruleForm = {
        mk_id: '', // 市场id
        seg_id: '', // 市场所属分类id
        booth_numbers: '', // 摊位编号
        booth_type: '', // 类型：1摊位，2为门面
        booth_footprint: '', // 摊位面积
        booth_version: '', // 摊位型号
        management_forms: '', // 经营状态
        booth_longitude: '', // 摊位经度
        booth_latitude: '' // 摊位纬度
      }
    },
    // 摊位型号
    GET_boothPaget () {
      GETboothPaget().then(res => {
        this.data.boothVersionData = res.data.booth_version_data
      })
    }
  },
  mounted () {
    this.get_market()
    this.GET_searchBoothType()
    this.GET_searchStatus()
    this.GET_boothList()
    this.GET_boothPaget()
  }
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.title2 {
  margin-top: 20px;
}
.footers {
  text-align: right;
  margin-top: 20px;
}
.elform {
  display: flex;
  justify-content: space-around;
}
</style>
